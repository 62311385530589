<template>
  <div class="videopage">
    <div class="topbox">
      <span @click="back" class="totalback">
        <i class="back el-icon-arrow-left"></i>
        <span class="backtext">{{$t('WDNPC_WEB_RETURN',{text:'返回'})}}</span>
      </span>
      <div class="smallLine"></div>
      <span class="tranincoursename">{{ courseName }}</span>
    </div>
    <div class="total videoBox">
      <div
        class="menu"
        :style="{ width: isCollapse == true ? '330px' : '0px' }"
      >
        <el-tree
          :data="CourseTreelist"
          :props="defaultProps"
          accordion
          highlight-current
          :default-expanded-keys="expandedKeys"
          node-key="id"
          ref="tree"
          class="wdc-select-tree"
          @node-click="handleNodeClick"
          @node-expand="nodeExpandClick"
          :style="{ marginLeft: isCollapse == true ? '0px' : '-500px' }"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <img
              v-if="data.type == 0 && data.parentId == 0 && node.expanded"
              class="longline"
              src="../../../assets/images/learning/longline.png"
              alt=""
            />
            <span
              :title="node.label"
              class="dy-label"
              :class="{ dyActive: resourcesId == data.detailId }"
              :style="{
                marginLeft:
                  data.parentId == 0 &&
                  data.childrenList &&
                  data.childrenList.length > 0
                    ? '0px'
                    : '20px'
              }"
            >
              <img
                v-if="data.type != 0"
                class="look"
                :src="getLookImg(data)"
                alt=""
              />
              <svg-icon v-if="data.type != 0" :icon-class="ConstData.courseType[data.type].icon"></svg-icon>
              {{ node.label }}
            </span>
            <span class="lock-icon"  v-if="data.lock">
              <svg-icon icon-class="lock"></svg-icon>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="bigline"></div>
      <div class="video" id="videobox">
        <div class="top">
          <div class="title">
            <!-- <img
              class="choose"
              :label="true"
              @click="choose"
              :src="getArrowImg()"
            /> -->
            <span class="coursename">{{ coursenametext }}</span>
          </div>
          <button class="next" @click="findNext()">{{$t('WDNPC_WEB_NEXT_ITEM',{text:'下一项'})}}</button>
        </div>
        <div class="chooseBox" @click="choose">
            <svg-icon :icon-class="isCollapse ? 'shouqi' : 'zhankai'" class-name="icon-img"></svg-icon>
        </div>
        <div
          class="container"
          id="player-con"
          style="height: calc(100vh - 153px)"
          v-if="this.type == 1 || this.type == 2"
        >
          <!-- 音频波形图 -->
          <img
            v-if="this.type == 2"
            class="music"
            src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/music.png"
          />
        </div>

         <!-- 
          $t('WDNPC_WEB_DOCUMENT_PREVIEW',{text:'文档预览'})
          detailId$t('WDNPC_WEB_RESOURCES',{text:'：资源'})id
          duration$t('WDNPC_WEB_TOTAL_DOCUMENT_PAGES',{text:'：文档总页数'})
         -->
        <doc-preview :detailId="+resourcesId" v-if="resourcesId && type == 3" :key="resourcesId" :duration="+duration"></doc-preview>

        <!-- <div v-if="type == 4" class="imgbox" id="imgContent"> -->
          <!-- <img class="img" :src="videoInfo.url" /> -->
          <img v-if="type == 4" :src="videoInfo.url" class="img" />
        <!-- </div> -->
        <div class="scromBox" v-if="type===6">
          <common-scrom
            :id="scrom.resId"
            :version="scrom.version"
            :courseItemId ="newcourse"
          />
        </div>
      </div>
      <el-dialog
        class="videoDialog"
        :visible.sync="dialogTableVisible"
        :close-on-click-modal="false"
        @close="closedialog"
      >
        <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
        <div class="tip">{{$t('WDNPC_WEB_PROMPT',{text:'提示'})}}</div>
        <div class="text">{{$t('WDNPC_WEB_NO_DRAGANDDROP_PROGRESS_DURING_LEARNING',{text:'学习时禁止拖放进度'})}}！</div>
        <button class="btn" @click="btnclosedialog(1)">{{$t('WDNPC_WEB_GOT_IT',{text:'知道了'})}}</button>
      </el-dialog>
      <el-dialog
        class="alertDialog"
        :visible.sync="continueDialogVisible"
        :close-on-click-modal="false"
        @close="closedialog"
      >
        <svg-icon icon-class="tips" class-name="icon-img tips-icon"></svg-icon>
        <div class="text">
          {{
            this.TrainBasicInfo.alertWindowTip ||
              $t('WDNPC_WEB_DEAR_ARE_THE_STUDENTS_STILL_THERE_PLEASE',{text:'亲爱的学员，还在吗？请不要走开，点击 ”我在“继续学习，否则您离开的时间将不计入学时'})
          }}
        </div>
        <button class="btn" @click="btnclosedialog(2)">{{$t('WDNPC_WEB_I_AM_CONTINUING_TO_STUDY',{text:'我在，继续学习'})}}</button>
      </el-dialog>
    </div>
    <facedialog
      v-if="verification"
      :confirmdialog="confirmdialog"
      :address="verificationAddress"
      :failAddress="verificationFailAddress"
      :type="1"
      :pauseFrame="true"
      @CloseSuccessFacedialog="CloseSuccessFacedialog"
      @failCloseFacedialog="failCloseFacedialog"
      :businessId="businessId"
      :taskId="taskId"
      :resId="resourcesInfo.detailId"
    ></facedialog>
    <!-- 开启全屏模式弹窗 -->
    <el-dialog
      class="fullScreen-dialog"
      :visible.sync="fullScreendialogVisible"
      width="400px"
    >
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="titles">提示</p>
      <p class="tipText">该课程被设置为全屏学习模式，退出后将不再计算有效学习时间。</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="fullScreenTest" style="width: 100%"
          >知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
import {goLiveHome} from "../../live/common/livePlatform";
import docPreview from './docPreview.vue';
import commonScrom from "../scrom/commonScrom.vue";
import frAliplary from "../../../utils/frAliplayer"
import TCPlayer from 'tcplayer'
let enterFullPath = {
  path:'',
  query:''
}
export default {
  name: 'courseVideo',
  components:{docPreview,commonScrom},
  data () {
    return {
      taskId:'',
      confirmdialog:{
        show:true,
      },// 人脸识别弹窗
      verification:false,
      businessId:'',
      newcourse:0,
      duration:'', // 文档总进度
      CourseTreelist: [],
      resourcesId: '',
      expandedKeys: [],
      resourcesList: [], //所有资源
      resourcesInfo: null, //当前播放的资源
      coursenametext: '',
      scrom: {
        resId: 0,
        version: '1.2'
      },
      defaultProps: {
        children: 'childrenList',
        label: 'name'
      },
      type: -1,
      detailId: -1, // 路由跳转时传入的id
      isCollapse: true,
      canPlay: false,
      catelogList: [],
      params: {
        courseId: 85,
        taskId: 1
      },
      TrainBasicInfo: {
        isAlertWindow: 0
      },
      is: true,
      videoInfo: {
        video: null,
        url: [],
        source: {},
        deadline: 0,
        index: 0,
      },
      IntervalName: null,
      player: null,
      tcplayer: null,
      name: '',
      pdfIntervalName: null,
      dialogTableVisible: false,
      continueDialogVisible: false,
      currentposition: null,
      allPlayEndIdList: [],
      alertTime: null,
      verificationAddress: {},
      verificationFailAddress: {},
      verificatioPpauseAddress: {},
      result: null,
      app: null,
      courseId:null,//当前课程id
      courseName:'',//当前播放的课程名称
      showAlertArr:[], // 获取视频播放中需要弹窗的时间点数据
      timeTStr:0, //视频当前播放时间点 用来弹出弹屏设置
      faceRecognizeTimer:null,// 人脸识别定时器
      faceRecognizeFail:false, // 人脸识别失败

      resizeTimer:null, // 全屏定时器
      webpageFlag:true,
      fullScreendialogVisible:false, // 全屏提示弹窗
      isFullScreen: false,
      saveLogTime:15,
      pauseTime:0,
      options: {
          // fileID: '243791581722038521', // 请传入需要播放的视频 fileID（必须）
          // appID: '1500020443', // 请传入点播账号的 appID（必须）
          // psign: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUwMDAyMDQ0MywiZmlsZUlkIjoiMjQzNzkxNTgxNzM1MDkxNDk2IiwiY3VycmVudFRpbWVTdGFtcCI6MTY4MjU2NTYwOSwiY29udGVudEluZm8iOnsiYXVkaW9WaWRlb1R5cGUiOiJPcmlnaW5hbCJ9LCJleHBpcmVUaW1lU3RhbXAiOjE2ODI2MTEyMDAsInVybEFjY2Vzc0luZm8iOnsiZG9tYWluIjoiMTUwMDAyMDQ0My52b2QyLm15cWNsb3VkLmNvbSIsInNjaGVtZSI6IkhUVFBTIn19.3zkXP4Cs-NxK9OKZ_4v1RsztZtCWDNbzXmChJmfEId8',
          autoplay: true,
          controlBar: {
              progressControl: true
          }
      },
      videoPlayChannel: '',
      task_show_facedialog_rule:{ //人脸识别规则
        range:'course',
        isOnce:false
      }
    }
  },
  created(){
    this.taskId = this.$route.query.taskId || this.$route.query.trainId
      this.initConfig();
  },
  async mounted () {
    this.courseName = this.$route.query.courseName;
    this.detailId = this.$route.query.detailId || '';
    this.courseId = this.$route.query.courseId;
    if (this.$route.query.taskType == 2) {
      await this.findfaceNeed() // 培训任务规则
    } else {
      await this.findTrainBasicInfoById() // 训练营 返回规则接口
    }
    /**
     * 是否开启全屏模式 isCourseFullScreen 0 否 1 是
     * 如果设置了弹窗关闭之后在获取目录大纲
     */
    if(this.TrainBasicInfo.isCourseFullScreen){
        // 设置全屏
        console.log(this.TrainBasicInfo.isCourseFullScreen,'isCourseFullScreen');
        this.fullScreendialogVisible = true; // 全屏文案提示
        this.checkFull(); // 监听全屏
    }else {
        await this.findCourseItemByCourseId();
    }   
    //监听当前页改变事件。
    if (this.app) {
      this.app.Sub.CurrentPageChange = this.eventHandle
    }
  },
  beforeRouteEnter (to, from, next) {
    // ...
    enterFullPath.path = from.path;
    enterFullPath.query = from.query;
    next();
  },
  methods: {
    initConfig() {
      let configArr = localStorage.getItem('configurationArr') && JSON.parse(localStorage.getItem('configurationArr'))
      configArr.forEach((item) => {
        switch (item.key) {
          case 'course_saveLog_time':
            this.saveLogTime = item.value || 15;
            break
          case 'video_play_channel':
            this.videoPlayChannel = item.value || '';
            break
          case 'task_show_facedialog_rule':
            this.task_show_facedialog_rule = item.value && JSON.parse(item.value);
            break
          default:
            break
        }
      })
      console.log(this.saveLogTime,'this.saveLogTime')
    },
    async fullScreenTest () {
      this.fullScreendialogVisible = false;
      this.isFullScreen = true;
      setTimeout(()=>{
        this.openFullScreen();
      }, 500)
      await this.findCourseItemByCourseId();
   
    },
    // 监听是否全屏
    checkFull() {
      const that = this;
      window.onresize = function () {
        if(that.resizeTimer){
          clearTimeout(that.resizeTimer)
        }
        that.$nextTick(()=>{
          that.resizeTimer = setTimeout(()=>{
              if (that.webpageFlag) {
                that.clientHeight = document.body.clientHeight;
                that.webpageFlag = false;
              }
              var sh = document.body.clientHeight;
              if (that.clientHeight!=sh) {//退出全屏
                that.isFullScreen = false;
                console.log('退出全屏===')
                // that.fullScreendialogVisible = true;
              } else {
                console.log('开启全屏===')
                that.isFullScreen = true;
              }
            }, 500)
        })
      }
    },
    // 开启全屏
    openFullScreen(){
      const htmlEle = document.documentElement;
      if (htmlEle.requestFullscreen) {
          htmlEle.requestFullscreen();
      } else if (htmlEle.mozRequestFullScreen) {
          htmlEle.mozRequestFullScreen();
      } else if (htmlEle.webkitRequestFullscreen) {
          htmlEle.webkitRequestFullscreen();
      } else if (htmlEle.msRequestFullscreen) {
          htmlEle.msRequestFullscreen();
      }
      
    },
    closeFullScreen(){
      if(document.exitFullScreen) {
          document.exitFullScreen();
      } else if(document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if(document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
      } else if(document.msExitFullscreen) {
          document.msExitFullscreen();
      }
      console.log('关闭全屏');
    },
    /**
     * 获取任务里下一个课程
     */
     getNextCourseInfo(){
        let params = {
            courseId:this.courseId, // 当前课程ID
            taskId:this.$route.query.trainId, // 当前任务训练营ID
        }
        /**
         * courseId 当前课程id
         * nextCourseId 下一个课程id
         * nextCourseName  下一个课程名称
         * nextCourseNullType nextCourseId 为空时的原因类型，0 代表已全部学完，1 代表下一个课程未解锁
         */
         this.$api.course.getNextCourseInfo({params}).then(async (res) => {
          console.log(res,'=====res')
          this.courseId = res.data.nextCourseId || res.data.courseId;
          this.courseName = res.data.nextCourseName || this.courseName;
          if(res.data.nextCourseNullType === 1 && !res.data.nextCourseId){
              this.$message({
                showClose: true,
                message: this.$t('WDNPC_WEB_THE_NEXT_COURSE_DOES_NOT_MEET_THE',{text:'下一个课程未达到解锁规则，请返回任务大纲，按规则进行学习'}),
                type: 'warning'
              })
          }else if(res.data.nextCourseNullType === 0 && !res.data.nextCourseId){
              this.$message({
                showClose: true,
                message: this.$t('WDNPC_WEB_THIS_IS_THE_LAST_CONTENT',{text:'已经是最后一个内容了'}),
                type: 'warning'
              });
          }else {
            this.resourcesList = []
            await this.findCourseItemByCourseId();
            console.log(this.resourcesList,'this.resourcesList')

            await this.playOneResources(this.resourcesList[0])
          }

        })
    },
    /**
     * 跨课程播放第一个视频
     */
    playOneResources(item){
        this.expandedKeys = [item.id]
        this.resourcesId = item.detailId
        this.coursenametext = item.name
        this.detailId = '' //只有第一次拿进来要看到的视频
        //当前播放的视频一定要有进度
        item.studyInfo = {
          ...item.studyInfo,
          studyProcess: 1
        }
        this.resourcesInfo = item
        if (!this.resourcesInfo.studyInfo) {
          this.resourcesInfo.studyInfo = {}
        }
        // this.progressSetting(item)
        if (this.TrainBasicInfo.isTrainTimePassed == 1) {
            this.$message({
              showClose: true,
              message: this.$t('WDNPC_WEB_NOT_STARTED_YET',{text:'尚未开始哦'}),
              type: 'warning'
            })
            return
        }
        this.resourcesPlay(item,1)

    },
    // 获取实训详情
    getdrillDetail (id) {
      this.$api.learning.getdrillDetail({ id }).then(res => {
        if (res.data) {
          window.open(
            window.location.origin +
              `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`
          )
        }
      })
    },
    getArrowImg () {
      if (this.isCollapse) {
        return '/static/images/right.png'
      }
      {
        return '/static/images/left.png'
      }
    },
    /**
     * 人脸识别成功确定事件
     */
     CloseSuccessFacedialog(){
        this.verification = false;
        this.faceRecognizeFail = false;
        if(!this.continueDialogVisible){
            this.player && this.player.play();
            this.tcplayer && this.tcplayer.play();
        }
     },
     /**
      * 人脸识别失败事件 或者过程中关闭了人脸
      */
      failCloseFacedialog(){
          console.log('关闭或失败切换视频')
          this.faceRecognizeFail = true;
      },
    async back () {
      //   this.$router.go(-1)
      /**
       * 返回之前保存进度
       */
      if(this.videoInfo.video){
        await this.postCourseLearningLogSaveLog();
      }
      if (this.$route.query.taskType == 2) {
          if(this.type==6){
            this.$router.push({
              path:enterFullPath.path,
              query:enterFullPath.query
            })
          }else{
            this.$route.params.ishint = 0;
            this.$router.back()
          }
      } else {
        // 训练营详情
        this.$router.push({
          path: this.$route.query.backPathName,
          query: {
            taskId: this.$route.query.trainId,
            backMoudle: this.$route.query.backMoudle,
            taskType: this.$route.query.taskType || null
          }
        })
      }
    },
    getLookImg (data) {
      //   console.log("树的data",data)
      if (data.studyInfo && data.studyInfo.studyProcess != 0) {
        return '/static/images/look.png'
      } else {
        return '/static/images/notlook.png'
      }
    },
    // 点击下一项
    async findNext () {
      let info = null
      this.resourcesList.forEach((item, index) => {
        if (item.detailId === this.resourcesInfo.detailId) {
          info = this.resourcesList[index + 1]
        }
      })
      // console.log(info,'info')
      if (info) {
        this.expandedKeys = [info.id]
        this.handleNodeClick(info)
      }
      if (info == undefined) {
        /**
         * 培训任务自动播放下一个课程
         */
        if(this.$route.query.taskType == 2){
          await this.getNextCourseInfo();
        }else {
          this.$message({
            showClose: true,
            message: this.$t('WDNPC_WEB_THIS_IS_THE_LAST_CONTENT',{text:'已经是最后一个内容了'}),
            type: 'warning'
          });
        }

      }
      // this.detailId = info.detailId
    },
    //   单个点击获取资源
    async handleNodeClick (data) {
      if (data.type != 0 && data.lock) {
        await this.postCourseLearningLogSaveLog() // 切换之前保存进度
        await this.findCourseItemByCourseId() //获取最新目录
        // 是最新的目录里面更新后的数据
        this.resourcesList.forEach(item => {
          if (item.detailId == data.detailId) {
            data = item
          }
        })
      }

      if (
        this.$route.query.taskType == 1 &&
        (data.type === 11 || data.type === 12)
      ) {
        this.$message.warning(this.$t('WDNPC_WEB_UNABLE_TO_LEARN_WITHOUT_CONTENT',{text:'无内容，无法学习'}))
        return false
      }
      if (data.lock) {
        console.log('data.type', data.type)
        this.$message.warning(
          this.$t('WDNPC_WEB_IF_THE_CONTENT_IS_NOT_ENABLED_THE',{text:'该内容未开启，开启条件为前面的内容学习进度达到'})+`${data.lockCourseRate}%`
        )
        console.log(data.lock, data.lockCourseRate, '%')
        return
      }
      if (data.type !== 0) {
        this.resourcesId = data.detailId;
        this.resourcesInfo = data;
        if (!this.resourcesInfo.studyInfo) {
          this.resourcesInfo.studyInfo = {};
        }
        this.videoInfo.fastForward = false;
      }
      this.resourcesPlay(data,1)
      this.updateProgress(this.CourseTreelist)
      this.$forceUpdate()
    },
    nodeExpandClick (data, node) {
      this.updateChildren(node)
    },
    updateChildren (list) {
      if (list.childNodes.length > 0) {
        list.childNodes.forEach(item => {
          item.expanded = true
          this.updateChildren(item)
        })
      }
    },
    // 资源播放
    async resourcesPlay (data,isOne) {
      this.newcourse = data.id;
      console.log('data',data);
      if (data.type == 0) {
        return
      } else if (data.type) {
        this.type = data.type
      }
      if (this.TrainBasicInfo.isTrainTimePassed == 1) {
        this.$message({
          showClose: true,
          message: this.$t('WDNPC_WEB_NOT_STARTED_YET',{text:'尚未开始哦'}),
          type: 'warning'
        })
        return
      }
      if (data.lock) {
        return
      }
      if (data.type !== 0) {
        this.coursenametext = data.name
      }
      // console.log(data,'data.type---')
      // 刚进入页面不需要弹人脸识别
      if(isOne){
        this.faceRecognizeFail = true;
      }
      //0 目录；1，视频；2，音频；3，文档；4，图片；6，scorm；9，试卷；10，练习；11，直播；12，实锤
      if (data.type == 1 || data.type == 2) {
        // 视频
        this.getVideoPlayURLById(data)
        this.clearPdfTimeing()
        if (data.type == 2) {
          this.courseClearTimeing()
        }
      } else if (data.type == 3) {
        // pdf
        this.duration = data.duration;
        this.pdfTiming(); // 保存接口
        this.courseClearTimeing(); // 清除定时器
        this.removePlay()
      } else if (data.type == 4) {
        // 图片
        this.getImageOrDocUrl(data)
        this.removePlay()
        this.pdfTiming()
        this.courseClearTimeing()
      } else if(data.type == 6){
        this.scrom.resId = data.resId
        this.scrom.version = data.sourceSign;
        this.removePlay();
        //查看scorm
        // let url = `${window.origin}/raas/resources/scrom/myScrom?resId=${data.resId}&version=${data.sourceSign||1.2}`;
        // window.open(url);
      }else if (data.type == 10) {
        this.saveLearningLog(10)
        this.$router.push({
          path: '/practice',
          query: {
            practiceId: data.detailId,
            trainId: this.$route.query.trainId,
            courseId: this.courseId,
            detailId: this.$route.query.detailId,
            studyProcess: this.$route.query.studyProcess,
            courseName: this.$route.query.courseName,
            taskType: this.$route.query.taskType || null,
            pathName: this.$route.path,
            backPathName: this.$route.query.backPathName,
            backPath: this.$route.query.backPath,
            id: this.$route.query.trainId,
            taskId: this.$route.query.taskId,
            backMoudle: this.$route.query.backMoudl
          }

        })
      } else if (data.type === 11) {
        this.saveLearningLog(11)
          if(data.type === 4 && data.isLiveReplay && data.status === 2 && !data.isLocked){
              this.$message.error(this.$t('WDNPC_WEB_THE_LIVE_BROADCAST_HAS_ENDED',{text:'直播已结束'}))
              return 
          }
          goLiveHome(this, data, data.liveRoomId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))
      } else if (data.type === 12) {
        this.saveLearningLog(12)
        this.getdrillDetail(data.detailId)
      }
    },
    /** 
     * 弹屏 弹窗关闭触发事件
     */
    closedialog () {
      if(!this.verification){
        try {
          this.player && this.player.play();
          this.tcplayer && this.tcplayer.play();
        } catch(e) {
          console.log('e', e);
        }
      }
    },
    /**
     * 弹屏 点击我知道了 触发事件
     */
    btnclosedialog (num) {
      if (num == 1) { // 禁止拖拽
        this.dialogTableVisible = false;
      } else if (num == 2) { // 弹屏
        this.continueDialogVisible = false;
      }
      // 如果正在人脸识别则不播放
      if(!this.verification){
        this.player && this.player.play();
        this.tcplayer && this.tcplayer.play();
      }
    },
    /** 收缩 tree */
    choose () {
      this.isCollapse = !this.isCollapse
    },
    //获取全部资源文件
    async getAllfile (courseData) {
      if (courseData && courseData.length) {
        courseData.forEach(item => {
          if (item.detailId == this.detailId) {
            console.log('1');
            this.expandedKeys = [item.id]
            this.resourcesId = item.detailId
            this.coursenametext = item.name
            this.detailId = '' //只有第一次拿进来要看到的视频
            //当前播放的视频一定要有进度
            item.studyInfo = {
              ...item.studyInfo,
              studyProcess: 1
            }
            this.resourcesInfo = item
            // this.progressSetting(item)
            if (this.TrainBasicInfo.isTrainTimePassed == 1) {
              this.$message({
                showClose: true,
                message: this.$t('WDNPC_WEB_NOT_STARTED_YET',{text:'尚未开始哦'}),
                type: 'warning'
              })
              return
            }
            this.resourcesPlay(item)
          }
          if (item.type) {
            console.log('2');
            this.resourcesList.push(item)
          } else if (item.childrenList && item.childrenList.length > 0) {
            console.log('3');
            return this.getAllfile(item.childrenList)
          }
        })
      }
    },
    // 递归循环 获取指定data
    updateProgress (list) {
      list.forEach(item => {
        if (item.detailId == this.resourcesId) {
          item.studyInfo = {
            ...item.studyInfo,
            studyProcess: 1,
            courseRate:this.CourseTreelist && this.CourseTreelist[0].courseRate
          }
        } else if (item.type == 0) {
          this.updateProgress(item.childrenList)
        }
      })
    },

    // 获取课程详情目录
    async findCourseItemByCourseId () {
      let params = {
        courseId:this.courseId || '',
        taskId:this.$route.query.trainId,
        taskType: this.$route.query.taskType || null
      }
      const { data } = await this.$api.course.findCourseItemByCourseId({
        params
      })
      this.catelogList = data
      this.CourseTreelist = data
      console.log('获取所有资源',this.CourseTreelist);
      // 所有资源
      await this.getAllfile(data)

    },
    /**
     * 判断视频是否防拖拽
     * 获取训练营设置规则
     */
    async findTrainBasicInfoById () {
      const params = {
        id: this.$route.query.trainId || 1
      }
      await this.$api.training
        .findTrainBasicInfoById({ params })
        .then(async res => {
          this.TrainBasicInfo = res.data
          this.alertTime = this.TrainBasicInfo.alertTime * 60 * 1000
        })
    },
    /**
     * 获取培训任务设置规则
     */
    async findfaceNeed () {
      await this.$api.examination
        .findfaceNeed(this.$route.query.trainId)
        .then(res => {
          this.TrainBasicInfo = res.data
          this.alertTime = this.TrainBasicInfo.alertTime * 60 * 1000
        })
    },
    // 获取视频播放地址
    async getVideoPlayURLById (data) {
      const params = {
        resId: data.detailId
      }
      this.is = true
      if (this.videoPlayChannel === 'tencent' && data.type == 1) {
        return this.$api.resource.getPlayAuthTokenInfo({ params }).then(res => {
          this.videoInfo.source = res.data
          try {
            this.player && this.player.dispose()
            this.tcplayer && this.tcplayer.dispose()
          } catch(e) {
            console.log('e', e);
          }
          if (res.data.sourceSing === '555') {
            this.gettcPlay(this.videoInfo)
          } else {
            const vi = Object.assign({}, this.videoInfo)
            vi.source.tencentPlayVideoResp = { playUrl: vi.source.playUrl }
            this.gettcPlay(vi)
          }
        })
      } else {
        return this.$api.resource.getPlayAuthToken({ params }).then(res => {
          this.videoInfo.source = res.data
          try {
            this.player && this.player.dispose()
            this.tcplayer && this.tcplayer.dispose()
          } catch(e) {
            console.log('e', e);
          }
          if (res.data.sourceSing === '555') {
            this.gettcPlay(this.videoInfo)
          } else {
            this.getaliPlay()
          }
        })
      }
    },
    // 获取图片文档
    async getImageOrDocUrl (data) {
      const params = {
        id: data.detailId
      }
      return this.$api.resource.getImageOrDocUrl({ params }).then(res => {
        if (res.data) {
          this.videoInfo.url = res.data
        }
      })
    },
    async progressSetting (dataInfo) {
      if (dataInfo && dataInfo.studyInfo) {
        const deadline = (this.player.getDuration() || dataInfo.duration || 0) * ((dataInfo.studyInfo.learnRate || 0) * 0.01)
        this.videoInfo.deadline =
          deadline >= (this.player.getDuration() || dataInfo.duration || 0)
            ? this.player.getDuration()
            : deadline
        if (dataInfo.studyInfo.playLength) {
          this.videoInfo.video.currentTime = dataInfo.studyInfo.playLength
        } else {
          this.videoInfo.video.currentTime =  this.videoInfo.deadline >= (this.player.getDuration() || dataInfo.duration || 0)
              ? 0
              : this.videoInfo.deadline || 0
        }
      }
    },
    async tcProgressSetting (dataInfo) {
      if (dataInfo && dataInfo.studyInfo) {
        const deadline = (this.tcplayer.duration() || dataInfo.duration || 0) * ((dataInfo.studyInfo.learnRate || 0) * 0.01)
        this.videoInfo.deadline =
          deadline >= (this.tcplayer.duration() || dataInfo.duration || 0)
            ? this.tcplayer.duration()
            : deadline
        if (dataInfo.studyInfo.playLength) {
          this.videoInfo.video.currentTime = dataInfo.studyInfo.playLength
        } else {
          this.videoInfo.video.currentTime =  this.videoInfo.deadline >= (this.tcplayer.duration() || dataInfo.duration || 0)
              ? 0
              : this.videoInfo.deadline || 0
        }
      }
    },
    removePlay () {
      var $ = require('jquery')
      if ($('#player-con').length > 0) {
        try {
          this.player && this.player.dispose()
          this.tcplayer && this.tcplayer.dispose()
        } catch(e) {
          console.log('e', e);
        }
        $('#player-con').empty()
      }
    },
    //观看课程信息保存
    async postCourseLearningLogSaveLog () {
      let cur = '',max = '';
      if(this.videoInfo.video){
        cur = this.videoInfo.video.currentTime && this.videoInfo.video.currentTime > 0 ? this.videoInfo.video.currentTime : 1;
        max = this.videoInfo.video.duration && this.videoInfo.video.duration > 0 ? this.videoInfo.video.duration : 1;
      }
      
      /**
       * saveLogtime 配置的秒数
       * 如果进度小于saveLogtime秒，直接保存总进度
       */

      console.log(+this.saveLogTime+5,'this.saveLogtime+5')
      if((this.videoInfo.video?.duration - this.videoInfo.video?.currentTime) <= (+this.saveLogTime+5)){
          cur = this.videoInfo.video.duration;
      }
      console.log(cur,'cur===')
      
      const data = {
        courseId: this.courseId || '',
        taskId: this.$route.query.trainId || '',
        taskType: this.$route.query.taskType || null, //1：任务；2：训练营
        courseItemId: this.resourcesInfo && this.resourcesInfo.id, // 素材资源id
        cur, // 当前时长
        max,
        pollingTime: this.saveLogTime || 15,
        platform:0,
        isFullScreen:this.isFullScreen
      }
      for (let key in data) {
        if (!data[key]) {
          console.log(key, 'key---', data[key])
        }
      }
      await this.$api.course
        .postCourseLearningLogSaveLog({ data })
        .then(res => {
          console.log(res)
        })
    },
    //记录用户学习时长
    async postUserLearningLog () {
      const data = {
        learningLength: this.saveLogTime || 15,
      }
      await this.$api.course.postUserLearningLog({ data })
    },
    // 清除课程定时器
    async courseClearTimeing () {
      if (this.courseIntervalName) {
        clearInterval(this.courseIntervalName)
        this.courseIntervalName = null
      }
    },
    // 课程定时器，5秒保存一次
    // 读取配置项保存时间
    async courseTiming () {
      if (this.courseIntervalName) {
        clearInterval(this.courseIntervalName)
      }
      const saveLogTime = +this.saveLogTime * 1000;
      this.courseIntervalName = setInterval(async () => {
        if (this.$route.name === 'coursePlay') {
          this.postCourseLearningLogSaveLog()
          this.postUserLearningLog()
        }
      }, saveLogTime)
      return this.courseIntervalName
    },
     // 获取显示弹窗提示的时间点的数组
    getShowAlertArrNum(totalTime,nums){
        let totalNum = totalTime/(nums+1)
        let showAlertArr = [];
        for(let i = 0; i<= nums+1; i++) {
            showAlertArr.push(Math.ceil(totalNum*i))
        }
        showAlertArr.shift()
        showAlertArr.pop()
        this.showAlertArr = showAlertArr;
    },
    /**
     * 课程设置按次数弹屏，获取按次数弹屏的时间段
     */
    setDialogNum(){
      // console.log(this.timeTStr,'this.timeTStr');
      console.log(Math.ceil(this.player.getCurrentTime()),'Math ceil(this.player.getCurrentTime())')
        if(this.timeTStr  !==  Math.ceil(this.player.getCurrentTime())){
            if(this.showAlertArr.length>0){
                if(this.showAlertArr.indexOf (Math.ceil(this.player.getCurrentTime()))!==-1){
                    this.player.pause()
                    this.continueDialogVisible =true
                    this.timeTStr =  Math.ceil(this.player.getCurrentTime());
                }
            }
        }
    },
    setTcDialogNum(){
      // console.log(this.timeTStr,'this.timeTStr');
      console.log(Math.ceil(this.tcplayer.currentTime()),'Math ceil(this.tcplayer.currentTime())')
        if(this.timeTStr  !==  Math.ceil(this.tcplayer.currentTime())){
            if(this.showAlertArr.length>0){
                if(this.showAlertArr.indexOf (Math.ceil(this.tcplayer.currentTime()))!==-1){
                    this.tcplayer.pause()
                    this.continueDialogVisible =true
                    this.timeTStr =  Math.ceil(this.tcplayer.currentTime());
                }
            }
        }
    },
    faceRecognizeDialog(){
        this.player.pause();
        this.verificationAddress = {};
        this.verificationFailAddress = {};
        this.verification = true;
        this.confirmdialog.show = true;
    },
    faceTcRecognizeDialog(){
        this.videoInfo.video.pause();
        this.verificationAddress = {};
        this.verificationFailAddress = {};
        this.verification = true;
        this.confirmdialog.show = true;
    },
    //开始阶段人脸识别
    startFace(){
      if(!this.task_show_facedialog_rule.isOnce){
        this.player && this.player.pause();
        this.videoInfo.video && this.videoInfo.video.pause();
        this.verificationAddress = {};
        this.verificationFailAddress = {};
        this.verification = true;
        this.confirmdialog.show = true;
      }else{
        let obj = {};
        if(this.task_show_facedialog_rule.range=='task'){
            obj = {
                type:1,
                taskId:this.$route.query.taskId
            }
        }else if(this.task_show_facedialog_rule.range=='course'){
            obj = {
                type:2,
                taskId:this.$route.query.taskId,
                courseId:this.courseId,
            }
        }else if(this.task_show_facedialog_rule.range=='video'){
            obj = {
                type:3,
                taskId:this.$route.query.taskId,
                courseId:this.courseId,
                resId:this.resourcesInfo.detailId
            }
        }
        this.viewFaceAccess(obj).then(res=>{
          if(res.data==0){//未弹出过人脸识别
            this.player && this.player.pause();
            this.videoInfo.video && this.videoInfo.video.pause();
            this.verificationAddress = {};
            this.verificationFailAddress = {};
            this.verification = true;
            this.confirmdialog.show = true;
          }
        })
      }
		},
		viewFaceAccess(obj){
      const data ={
          ...obj
      }
      return new Promise((resolve,reject)=>{
        this.$api.learning.viewFaceAccess(data).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
      })
    },
    setFaceRecognizeDialog(){
      let time = this.TrainBasicInfo.courseFaceRecognizeTime * 60 * 1000;
      console.log(time,'time=====')
      this.faceRecognizeTimer = setInterval(() => {
        console.log('定时器人脸识别222')
        this.faceRecognizeDialog()
        clearInterval(this.faceRecognizeTimer)
      }, time)
    },
    setTcFaceRecognizeDialog(){
      let time = this.TrainBasicInfo.courseFaceRecognizeTime * 60 * 1000;
      console.log(time,'1122time=====')
      this.faceRecognizeTimer = setInterval(() => {
        console.log('定时器人脸识别232')
        this.faceTcRecognizeDialog()
        clearInterval(this.faceRecognizeTimer)
      }, time)
    },
    gettcPlay(videoInfo) {
      this.businessId = this.resourcesInfo.courseId;
      const timestamp = Date.now()
      var $ = require('jquery')
      $('#player-con').height('calc(100vh - 153px)')
      $('#player-con').children().remove()
      try {
        this.tcplayer && this.tcplayer.dispose()
      } catch(e) {
        console.log('e', e);
      }
      const boarddiv = `<video id="player-container-id_${this.businessId}_${timestamp}" style="width:100%; height:100%;" preload="auto" playsinline webkit-playsinline></video>`
      $('#player-con').append(boarddiv)
      // $('#player-container-id').width('100%').height('100%');
      const options = Object.assign({}, this.options)
      if (this.TrainBasicInfo.isDoubleSpeed) {
          options.controlBar.playbackRateMenuButton = false
      }
      if (+this.TrainBasicInfo.isDraw === 1 && (!this.resourcesInfo.studyInfo || (this.resourcesInfo.studyInfo.learnRate || 0) < 100)) {
        options.controlBar.progressControl = false
      }
      if (videoInfo.source.tencentPlayVideoResp.psign) {
        options.fileID = videoInfo.source.tencentPlayVideoResp.fileId
        options.appID = videoInfo.source.tencentPlayVideoResp.appId
        options.psign = videoInfo.source.tencentPlayVideoResp.psign
      }
      if (videoInfo.source.tencentPlayVideoResp.playUrl) {
        options.sources = [{src: videoInfo.source.tencentPlayVideoResp.playUrl}]
      }
      this.tcplayer = TCPlayer(`player-container-id_${this.businessId}_${timestamp}`, options)
      this.tcplayer.on('ready', () => {
        // console.log('videoready=========================')
        /** 
         * isAlertWindow: 弹屏开启 1 开启 0 关闭
         * alertCount：弹屏次数
         * alertType：弹屏类型 1 按次数 0 按时间
         */
        if(this.TrainBasicInfo.isAlertWindow && this.TrainBasicInfo.alertType){
            this.getShowAlertArrNum(this.resourcesInfo.duration,this.TrainBasicInfo.alertCount)
        }

        /**
         * 记录上次播放进度
         */
        if (
          this.resourcesInfo.studyInfo && this.resourcesInfo.studyInfo.playLength + 5 <= (this.resourcesInfo.duration || this.tcplayer.duration())
        ) {
          this.tcplayer.currentTime(this.resourcesInfo.studyInfo.playLength)
        } else {
          this.tcplayer.currentTime(0)
        }
      }) 
      // /** 开启全屏事件监听 */
      // this.player.on('requestFullScreen',async () => {
      //     console.log('开启全屏');
      // })
      // /** 取消全屏事件 */
      // this.player.on('cancelFullScreen', async () => {
      //     console.log('取消全屏');
      // })
      /* eslint-disable */
      this.tcplayer.on('play', async (e) => {
        console.log(this.videoInfo.video.currentTime,this.tcplayer.currentTime(),'开始播放当前时间this.videoInfo.video.currentTime=====')
        /** 开启全屏模式 */
        // this.player.fullscreenService.requestFullScreen();
        /** 清除人脸识别 */
        if(this.faceRecognizeTimer){
            clearInterval(this.faceRecognizeTimer)
        }
        
        if ((+this.$route.query.studyProcess || 0) < 100) {
          if (this.timer) {
            clearInterval(this.timer)
          }
          /**
           * 弹屏按时间
           * isAlertWindow: 弹屏开启 1 开启 0 关闭
           * alertType: 弹屏类型 1 按次数 0 按时间
           * alertTime：弹屏时间
           */
          if (this.TrainBasicInfo.isAlertWindow && !this.TrainBasicInfo.alertType) {
            console.log('弹屏1111')
            this.timer = setInterval(() => {
              console.log('弹屏222')
              this.tcplayer.pause()
              this.continueDialogVisible = true;
              clearInterval(this.timer)
            }, this.alertTime)
          }
        }
         this.videoInfo.fastForward = false
        if(!this.resourcesInfo.studyInfo || (this.resourcesInfo.studyInfo.learnRate || 0) < 100){
            if (+this.TrainBasicInfo.isDraw === 1 ) {
                /**
                 * 培训任务设置视频防拖拽 调用此方法
                 */
              this.tcProgressSetting(this.resourcesInfo);
            }
            if(this.TrainBasicInfo.courseFaceRecognizeTime && this.task_show_facedialog_rule.range=='video' && (+this.TrainBasicInfo.isCourseFaceRecognize === 1)){
              this.setTcFaceRecognizeDialog()
            } 
        }
        // hanmeng 人脸

        await this.courseClearTimeing();
        await this.courseTiming();
      })
      this.tcplayer.on('pause', async () => {
        console.log(this.videoInfo.video.currentTime,'暂停播放当前时间this.videoInfo.video.currentTime=====')
        this.pauseTime = this.videoInfo.video.currentTime;
        this.is = false
        await this.courseClearTimeing();
      })
      /**
       * 视频播放完成
       */
      this.tcplayer.on('ended', async () => {
          /**
           * 视频播放完成 清楚弹窗定时器
           */
        this.continueDialogVisible = false; // 多加一层保障
        clearInterval(this.timer); // 清除弹屏定时器
        clearInterval(this.faceRecognizeTimer); // 清除人脸识别定时器
        await this.postCourseLearningLogSaveLog();
        await this.postUserLearningLog();
        this.allPlayEndIdList.push(this.resourcesInfo.detailId)
        this.is = true
        this.courseClearTimeing()
        this.videoInfo.deadline = 0
        /**
         * 完成 自动播放下一个
         */
        this.findNext()
      })

      this.videoInfo.video = document.querySelector('video')
      if (!this.videoInfo.video) {
        this.videoInfo.video = document.querySelector('audio')
      }
      if (!this.videoInfo.video) {
        return
      }
      /**
       * 视频进度发生变化
       */
      // this.videoInfo.video.addEventListener('timeupdate', () => {
      this.tcplayer.on('timeupdate', async () => {
        /**
         * 人脸验证，规则开启人脸识别并且设置 分钟
         * isCourseFaceRecognize 1 开启 0 关闭
         * 暂停播放
         * 第一次观看没有进度
         */
         console.log(this.TrainBasicInfo,'this.TrainBasicInfo====xxxxxxxxxxxxxxxxxxxxxx',this.resourcesInfo.studyInfo, this.catelogList[0]?.courseRate)
        if(
          ((this.task_show_facedialog_rule.range=='video' && (!this.resourcesInfo.studyInfo || (this.resourcesInfo.studyInfo.learnRate || 0) < 100)) ||
          (this.task_show_facedialog_rule.range=='task' && this.taskInfo.studyProcess<100 && sessionStorage.getItem('isFaceByTask')!=='1') ||
          (this.task_show_facedialog_rule.range=='course' && (!this.resourcesInfo.studyInfo || this.resourcesInfo.studyInfo.courseRate==false || this.catelogList[0]?.courseRate==false)) && localStorage.getItem('currentCourseId')!=this.courseId) && (+this.TrainBasicInfo.isCourseFaceRecognize === 1)
        ){
          console.log('hanmeng 进入人脸识别',this.resourcesInfo);
          if(this.faceRecognizeFail){
              // 人脸识别失败或者过程中关闭了人脸识别弹窗点击开始播放再次弹起
              console.log('hanmeng 人脸识别失败或者过程中关闭了人脸识别弹窗点击开始播放再次弹起');
              this.startFace()
          }
        }
        /**
         * 设置按次数弹屏
         * 如果弹屏开始并且是按次数
         * 首次进入弹屏
         */
        if(this.TrainBasicInfo.isAlertWindow && this.TrainBasicInfo.alertType && (+this.$route.query.studyProcess || 0) < 100){
            this.setTcDialogNum()
        }
        // console.log(this.videoInfo.video.currentTime,this.videoInfo.deadline,'进度条变化this.videoInfo.video.currentTime')
        // 视频进度发生变化
        // this.videoInfo.video.showHideControls()
        this.videoInfo.fastForward = true
        if (
          !this.videoInfo.video.seeking &&
          this.videoInfo.video.currentTime > this.videoInfo.deadline
        ) {
          this.videoInfo.deadline = this.videoInfo.video.currentTime + 3
          let learnRate = Math.ceil(
            ((this.videoInfo.deadline || 0) /
              (this.tcplayer.duration() || 0)) *
              100
          )
          learnRate = learnRate > 100 ? 100 : learnRate
          this.resourcesInfo.studyInfo.learnRate = learnRate >= this.resourcesInfo.studyInfo.learnRate ? learnRate || 0
              : this.resourcesInfo.studyInfo.learnRate || 0
          this.resourcesInfo.studyInfo.playLength = this.videoInfo.deadline >= this.resourcesInfo.studyInfo.playLength
              ? this.videoInfo.deadline - 3
              : this.resourcesInfo.studyInfo.playLength
        }

        // console.log(this.videoInfo.video.currentTime,this.videoInfo.deadline,'进度条变化----this.videoInfo.video.currentTime')

      })

      // this.videoInfo.video.addEventListener('seeking', () => {
        this.tcplayer.on('seeking', async (e) => {
        // 视频拖拽以后把弹窗的临时时间重制
        if (e.target.player._TimeUpdateStamp && this.videoInfo.video.currentTime - e.target.player._TimeUpdateStamp > 1) {
          this.timeTStr =0;
          console.log(this.pauseTime,'this.pauseTime=======')
          // if(this.pauseTime != this.videoInfo.video.currentTime && (this.videoInfo.video.currentTime > this.videoInfo.deadline)){
            // this.videoInfo.video.currentTime = this.pauseTime;
          // }
          console.log(this.timeTStr,'seeking--timeTStr')
          console.log(this.videoInfo.deadline,'seeking--this.videoInfo.deadline')
          console.log(this.videoInfo.video.currentTime,'seeking---this.videoInfo.video.currentTime')
          console.log(this.tcplayer.currentTime(),'seeking---this.tcplayer.currentTime()')

          if (this.allPlayEndIdList.indexOf(this.resourcesInfo.detailId) != -1) { return };
          
          if (
            this.resourcesInfo.studyInfo &&
            this.resourcesInfo.studyInfo.learnRate != 100
          ) {
            
            if (
              this.tcplayer.currentTime() > (this.videoInfo.deadline || 0) &&
              +this.TrainBasicInfo.isDraw == 1
            ) {
              console.log(1111111111111111111)
              this.videoInfo.video.currentTime = this.videoInfo.deadline - 3.3;
              console.log(this.videoInfo.video.currentTime,'seeking--this.videoInfo.video.currentTime')
              if (
                this.videoInfo.fastForward &&
                this.resourcesInfo.studyInfo &&
                this.resourcesInfo.studyInfo.learnRate != 100
              ) {
                this.tcplayer.pause()
                this.dialogTableVisible = true
              }
            }
          }
        }
      })
    },
    /**
     * 视频 音频播放器
     */
    async getaliPlay () {
      // 人脸识别需要的id
      this.businessId = this.resourcesInfo.courseId;
      var $ = require('jquery')
      $('#player-con').height('calc(100vh - 153px)')
      $('#player-con').children().remove()
      if ($('#J_prismPlayer').length > 0) {
        this.player && this.player.dispose()
        $('#J_prismPlayer').remove()
      }
      const boarddiv = '<div id="J_prismPlayer"></div>'
      $('#player-con').append(boarddiv)
      $('#J_prismPlayer').height('100%');
      console.log(this.coursenametext.indexOf('m3u8'))
      let format = ''
      let mediaType = ''
      //   if (this.type == 1) {
      //     // format = 'm3u8';
      //     // source = `{"LD": "${this.videoInfo.url[0]}","SD": "${this.videoInfo.url[1]}"}`
      //   } else
      if (this.type == 2) {
        format = 'mp3'
        // source = this.videoInfo.url[0]
        mediaType = 'audio'
      }
      console.log('23223323232',frAliplary.aliplaryConverter['fr-FR']);
      this.videoInfo.deadline = 0
      let plugin = null;
      let m3u8Plugin = null;
      console.log(this.videoInfo,'信息');
      if(this.videoInfo.source?.sourceSing &&(this.videoInfo.source?.sourceSing=='222' || this.videoInfo.source?.sourceSing=='333')){
        plugin= {
          "id": "J_prismPlayer",
          "source": this.videoInfo?.source.playUrl,
          "width": "100%",
          "height": "500px",
          "autoplay": true,
          "isLive": false,
          "rePlay": false,
          "playsinline": true,
          "preload": false,
          "controlBarVisibility": "hover",
          "useH5Prism": true
        };
      }else{
        m3u8Plugin = {
          id: 'J_prismPlayer',
          vid: this.videoInfo.source.videoMeta.videoId,
          playauth: this.videoInfo.source.playAuth,
          cover: this.videoInfo.source.videoMeta.coverURL,
          width: '100%',
          height: '500px',
          autoplay: true,
          preload: false,
          isLive: false,
          useH5Prism: true,
          language: frAliplary.aliplaryConverter[localStorage.getItem('current_language')?localStorage.getItem('current_language'):'zh-CN'],
          languageTexts:{
            'fr-fr':frAliplary.frAliplary
          },
        }
        plugin = {
          id: 'J_prismPlayer',
          vid: this.videoInfo.source.videoMeta.videoId,
          playauth: this.videoInfo.source.playAuth,
          cover: this.videoInfo.source.videoMeta.coverURL,
          width: '100%',
          height: '500px',
          autoplay: true,
          preload: false,
          isLive: false,
          useH5Prism: true,
          format: format || 'm3u8',
          mediaType: mediaType || 'video',
          language: frAliplary.aliplaryConverter[localStorage.getItem('current_language')?localStorage.getItem('current_language'):'zh-CN'],
          languageTexts:{
            'fr-fr':frAliplary.frAliplary
          },
        }
      }
      /* eslint-disable */
      this.player = new Aliplayer(
        this.coursenametext.indexOf('m3u8') !== -1 ? m3u8Plugin : plugin
      )
      /** 隐藏倍速  指对视频生效 */
      if(this.TrainBasicInfo.isDoubleSpeed && document.querySelector('video')){
        document.querySelector('.prism-setting-speed').style = 'display:none';
      }
  
      this.player.on('ready', () => {
        // console.log('videoready=========================')
        /** 
         * isAlertWindow: 弹屏开启 1 开启 0 关闭
         * alertCount：弹屏次数
         * alertType：弹屏类型 1 按次数 0 按时间
         */
        if(this.TrainBasicInfo.isAlertWindow && this.TrainBasicInfo.alertType){
            this.getShowAlertArrNum(this.player.getDuration(),this.TrainBasicInfo.alertCount)
        }

        /**
         * 记录上次播放进度
         */
        if (
          this.resourcesInfo.studyInfo && this.resourcesInfo.studyInfo.playLength + 5 <= (this.videoInfo.source.videoMeta?.duration || this.player.getDuration())
        ) {
          this.player.seek(this.resourcesInfo.studyInfo.playLength)
        } else {
          this.player.seek(1)
        }
      }) 
      // /** 开启全屏事件监听 */
      // this.player.on('requestFullScreen',async () => {
      //     console.log('开启全屏');
      // })
      // /** 取消全屏事件 */
      // this.player.on('cancelFullScreen', async () => {
      //     console.log('取消全屏');
      // })
      /* eslint-disable */
      this.player.on('play', async () => {
        console.log(this.videoInfo.video.currentTime,this.player.getCurrentTime(),'开始播放当前时间this.videoInfo.video.currentTime=====')
        /** 开启全屏模式 */
        // this.player.fullscreenService.requestFullScreen();
        /** 清除人脸识别 */
        if(this.faceRecognizeTimer){
            clearInterval(this.faceRecognizeTimer)
        }
        
        if ((+this.$route.query.studyProcess || 0) < 100) {
          if (this.timer) {
            clearInterval(this.timer)
          }
          /**
           * 弹屏按时间
           * isAlertWindow: 弹屏开启 1 开启 0 关闭
           * alertType: 弹屏类型 1 按次数 0 按时间
           * alertTime：弹屏时间
           */
          if (this.TrainBasicInfo.isAlertWindow && !this.TrainBasicInfo.alertType) {
            console.log('弹屏1111')
            this.timer = setInterval(() => {
              console.log('弹屏222')
              this.player.pause()
              this.continueDialogVisible = true;
              clearInterval(this.timer)
            }, this.alertTime)
          }
        }
        /**
         * 人脸验证，规则开启人脸识别并且设置 分钟
         * isCourseFaceRecognize 1 开启 0 关闭
         * 暂停播放
         * 第一次观看没有进度
         */
        console.log(this.TrainBasicInfo,'this.TrainBasicInfo====',this.resourcesInfo,this.catelogList)
        if(
          ((this.task_show_facedialog_rule.range=='video' && (!this.resourcesInfo.studyInfo || (this.resourcesInfo.studyInfo.learnRate || 0) < 100)) ||
              (this.task_show_facedialog_rule.range=='task' && this.taskInfo.studyProcess<100 && sessionStorage.getItem('isFaceByTask')!=='1') ||
              (this.task_show_facedialog_rule.range=='course' && (!this.resourcesInfo.studyInfo || this.resourcesInfo.studyInfo.courseRate==false || this.catelogList[0]?.courseRate==false)) && localStorage.getItem('currentCourseId')!=this.courseId) && (+this.TrainBasicInfo.isCourseFaceRecognize === 1)
        ){
          console.log('进入人脸识别',this.catelogList,this.resourcesInfo);
          if(this.faceRecognizeFail){
              // 人脸识别失败或者过程中关闭了人脸识别弹窗点击开始播放再次弹起
              console.log('人脸识别失败或者过程中关闭了人脸识别弹窗点击开始播放再次弹起');
             this.startFace(1)
          }else if (this.TrainBasicInfo.courseFaceRecognizeTime) {
            // 设置了人脸识别间隔时间
            console.log('定时器人脸识别1111')
            this.setFaceRecognizeDialog()
          }
        }
        
        
        this.videoInfo.fastForward = false
        if (
          this.resourcesInfo.studyInfo &&
          this.resourcesInfo.studyInfo.learnRate < 100 &&
          +this.TrainBasicInfo.isDraw === 1
        ) {
            /**
             * 培训任务设置视频防拖拽 调用此方法
             */
          this.progressSetting(this.resourcesInfo);
        }
        await this.courseClearTimeing();
        await this.courseTiming();
      })
      this.player.on('pause', async () => {
        console.log(this.videoInfo.video.currentTime,'暂停播放当前时间this.videoInfo.video.currentTime=====')
        this.pauseTime = this.videoInfo.video.currentTime;
        this.is = false
        await this.courseClearTimeing();
      })
      /**
       * 视频播放完成
       */
      this.player.on('ended', async () => {
          /**
           * 视频播放完成 清楚弹窗定时器
           */
          this.continueDialogVisible = false; // 多加一层保障
          clearInterval(this.timer); // 清除弹屏定时器
          clearInterval(this.faceRecognizeTimer); // 清除人脸识别定时器
          await this.postCourseLearningLogSaveLog();
          await this.postUserLearningLog();
        this.allPlayEndIdList.push(this.resourcesInfo.detailId)
        this.is = true
        this.courseClearTimeing()
        this.videoInfo.deadline = 0
        /**
         * 完成 自动播放下一个
         */
        this.findNext()

      })

      this.videoInfo.video = document.querySelector('video')
      if (!this.videoInfo.video) {
        this.videoInfo.video = document.querySelector('audio')
      }
      if (!this.videoInfo.video) {
        return
      }
      /**
       * 视频进度发生变化
       */
      this.videoInfo.video.addEventListener('timeupdate', () => {
        /**
         * 设置按次数弹屏
         * 如果弹屏开始并且是按次数
         * 首次进入弹屏
         */
        if(this.TrainBasicInfo.isAlertWindow && this.TrainBasicInfo.alertType && (+this.$route.query.studyProcess || 0) < 100){
            this.setDialogNum()
        }
        // console.log(this.videoInfo.video.currentTime,this.videoInfo.deadline,'进度条变化this.videoInfo.video.currentTime')
        // 视频进度发生变化
        // this.videoInfo.video.showHideControls()
        this.videoInfo.fastForward = true
        if (
          !this.videoInfo.video.seeking &&
          this.videoInfo.video.currentTime > this.videoInfo.deadline
        ) {
          this.videoInfo.deadline = this.videoInfo.video.currentTime + 3
          
          let learnRate = Math.ceil(
            ((this.videoInfo.deadline || 0) /
              (this.player.getDuration() || 0)) *
              100
          )
          learnRate = learnRate > 100 ? 100 : learnRate
          this.resourcesInfo.studyInfo.learnRate = learnRate >= this.resourcesInfo.studyInfo.learnRate ? learnRate || 0
              : this.resourcesInfo.studyInfo.learnRate || 0
          this.resourcesInfo.studyInfo.playLength = this.videoInfo.deadline >= this.resourcesInfo.studyInfo.playLength
              ? this.videoInfo.deadline - 3
              : this.resourcesInfo.studyInfo.playLength
        }

        // console.log(this.videoInfo.video.currentTime,this.videoInfo.deadline,'进度条变化----this.videoInfo.video.currentTime')

      })

      this.videoInfo.video.addEventListener('seeking',  async (e) => {
        // 视频拖拽以后把弹窗的临时时间重制
        if (e.target.player._TimeUpdateStamp && this.videoInfo.video.currentTime - e.target.player._TimeUpdateStamp > 1) {
          this.timeTStr =0;
          console.log(this.pauseTime,'this.pauseTime=======')
          // if(this.pauseTime != this.videoInfo.video.currentTime && (this.videoInfo.video.currentTime > this.videoInfo.deadline)){
            // this.videoInfo.video.currentTime = this.pauseTime;
          // }
          console.log(this.timeTStr,'seeking--timeTStr')
          console.log(this.videoInfo.deadline,'seeking--this.videoInfo.deadline')
          console.log(this.videoInfo.video.currentTime,'seeking---this.videoInfo.video.currentTime')
          console.log(this.player.getCurrentTime(),'seeking---this.player.getCurrentTime()')

          if (this.allPlayEndIdList.indexOf(this.resourcesInfo.detailId) != -1) { return };
          
          if (
            this.resourcesInfo.studyInfo &&
            this.resourcesInfo.studyInfo.learnRate != 100
          ) {
            
            if (
              this.player.getCurrentTime() > (this.videoInfo.deadline || 0) &&
              +this.TrainBasicInfo.isDraw == 1
            ) {
              console.log(1111111111111111111)
              this.videoInfo.video.currentTime = this.videoInfo.deadline - 3.3;
              console.log(this.videoInfo.video.currentTime,'seeking--this.videoInfo.video.currentTime')
              if (
                this.videoInfo.fastForward &&
                this.resourcesInfo.studyInfo &&
                this.resourcesInfo.studyInfo.learnRate != 100
              ) {
                this.player.pause()
                this.dialogTableVisible = true
              }
            }
          }
       }
      })
    },
    pdfTiming () {
      if (this.pdfIntervalName) {
        clearInterval(this.pdfIntervalName)
      }
      const saveLogTime = +this.saveLogTime * 1000;
      this.pdfIntervalName = setInterval(() => {
        this.saveLearningLog()
        this.postUserLearningLog()
      }, saveLogTime);
    },
    clearPdfTimeing () {
      if (this.pdfIntervalName) {
        clearInterval(this.pdfIntervalName)
        this.pdfIntervalName = null
        // console.log("清楚定时器");
      }
    },
    // 文档保存进度
    async saveLearningLog (type) {
     
      const data = {
        courseId: this.courseId || '',
        taskId: this.$route.query.trainId || '',
        taskType: this.$route.query.taskType || null,
        courseItemId: this.resourcesInfo.id || '',
        cur: localStorage.getItem('page') || 1,
        max: localStorage.getItem('totalpage') || 1,
        pollingTime: type ? 1 : (this.saveLogTime || 15),
        platform:0,
        isFullScreen:this.isFullScreen
      }
      await this.$api.course.postCourseLearningLogSaveLog({ data }).then(() => {
        // console.log(res)
      })
      return true
    }
  },
  beforeDestroy() {
      // 离开页面退出全屏
      this.closeFullScreen();
  },
  destroyed () {
    this.courseClearTimeing()
    this.clearPdfTimeing()
    this.player && this.player.pause()
    this.tcplayer && this.tcplayer.pause()
    if ($('#Vidpreview')) {
      $('#Vidpreview').remove()
    }
  }
}
</script>
<style lang="less">
@import '../css/video.styl';
</style>
<style lang="less" scoped>
/deep/ .player-container-id-dimensions {
  width: 100%;
}
/deep/ .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  border: 0; 
  background-color: transparent;
}
/deep/ .video-js .vjs-progress-control {
  top: 0;
  overflow: visible;
}
// /deep/ .video-js .vjs-custom-control-spacer {
//     display: block;
// }
</style>